import { Fragment, MouseEvent, MouseEventHandler, useContext, useState } from 'react';
import { Link as LinkRouter, NavLink, Route, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { stringifyQuery } from '../../../../utils';
import isEmpty from '../../../../utils/isEmpty';
import { formatPath } from '../../../../utils/path';
import Avatar from '../../../common/base/Avatar';
import Badge from '../../../common/base/Badge';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon, { StyledIcon } from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import Search from '../../../common/Search';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import { metric_categories } from '../Metrics/metric-categories';
import { DateRange } from '../Metrics/MetricDatePicker';
import { ViewsContext, ViewsContextProvider } from '../ViewsContext';
import NavFooter, { StyledBeamerButton } from './NavFooter';
import ResourceLink from './ResourceLink';
import TeamSelection from './TeamSelection/TeamSelection';
import Tooltip from '../../../common/base/Tooltip';
import { useHistory } from 'react-router';
import { useIsDegradedBanner } from '../../../hooks/useIsDegradedBanner';

export const StyledDashboardNav = styled.div(({ theme }) => {
  const degraded_banner_active = useIsDegradedBanner();

  return css`
    display: flex;
    position: sticky;
    top: ${degraded_banner_active ? '32px' : 0};
    max-height: ${degraded_banner_active ? 'calc(100vh - 32px)' : '100vh'};
    min-height: ${degraded_banner_active ? 'calc(100vh - 32px)' : '100vh'};
    flex-direction: column;
    background-color: ${theme.colors.surface.base.background};
    border-right: ${theme.border};
    z-index: ${theme.zindex.sticky + 2};
  `;
});

export const StyledDashboardNavSection = styled(Div)<{
  grow?: boolean;
  border?: boolean;
}>(
  ({ theme, border, grow }) => css`
    flex-grow: ${grow ? 1 : 0};
    position: relative;
    ${border &&
    css`
      &::before {
        content: '';
        height: 1px;
        left: 0;
        right: 0;
        top: 0;
        background-color: ${theme.colors.outline.neutral};
        position: absolute;
      }
    `};
  `,
);

const StyledNavLinkButton = styled(Icon)<{ opened: boolean }>(
  ({ opened }) => css`
    ${opened &&
    css`
      display: inline-block !important;
    `};
  `,
);

export const StyledPrimaryNavLink = styled(LinkRouter)``;

export const StyledNavLinks = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    line-height: 100%;

    ${StyledNavLinkButton} {
      display: none;
    }

    ${StyledIcon} {
      cursor: pointer;
    }

    > a,
    > button,
    > ${StyledBeamerButton} {
      display: flex;
      align-items: center;
      margin-top: ${theme.spacing(1)};
      position: relative;

      color: ${theme.colors.on.neutral.primary_neutral};
      svg,
      > span {
        color: ${theme.colors.on.neutral.secondary_neutral};
        fill: ${theme.colors.on.neutral.secondary_neutral};
      }
      text-decoration: none;
      padding: ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(2)};
      border-radius: ${theme.radius.normal};
      &:hover {
        background-color: ${theme.colors.surface.base.variant_surface};
        ${StyledNavLinkButton} {
          display: inline-block;
        }
      }
      &.active {
        background-color: ${theme.colors.surface.base.variant_surface};
        ${StyledNavLinkButton} {
          display: inline-block;
        }
      }

      &:focus-visible {
        outline: none;
        &::before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large};
        }
      }
    }

    ${StyledPrimaryNavLink} {
      color: ${theme.colors.on.neutral.primary};
      svg,
      > span {
        color: ${theme.colors.on.neutral.primary};
        fill: ${theme.colors.on.neutral.primary};
      }
    }
  `,
);

const StyledViewsContainer = styled(Div)(
  ({ theme }) => css`
    margin-right: -4px;
    padding-right: 4px;
    margin-bottom: -4px;
    padding-bottom: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: ${theme.pxToRem(15)};
      top: ${theme.pxToRem(8)};
      bottom: ${theme.pxToRem(8)};
      background-color: ${theme.colors.outline.neutral};
      width: 1px;
    }

    ${StyledNavLinks} {
      > a:not(.active) {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }
    }
  `,
);

const StyledActivityIndicator = styled(Div)<{ active: boolean }>(
  ({ theme, active }) => css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${active ? theme.colors.on.neutral.success : theme.colors.on.neutral.danger};
    border: 4px solid
      ${active ? theme.colors.surface.container.success : theme.colors.surface.container.danger};
    position: relative;
    z-index: 0;
    margin: 0 ${theme.spacing(1.5)};
  `,
);

const inspect_sections = [
  {
    key: 'requests',
    path: `/requests`,
    label: 'Requests',
    icon: 'requests' as const,
  },
  {
    key: 'events',
    path: `/events`,
    label: 'Events',
    icon: 'events' as const,
  },
  {
    key: 'bookmarks',
    path: `/bookmarks`,
    label: 'Bookmarks',
    icon: 'bookmarks' as const,
  },
];

const Views = ({ type, prefix }: { type: 'events' | 'requests'; prefix?: 'cli' }) => {
  const { views } = useContext(ViewsContext);
  const { pathname } = useLocation();

  const { active_cli_sessions } = useContext(DashboardContext);
  const is_cli_active = active_cli_sessions && active_cli_sessions.length > 0;

  const [search, setSearch] = useState('');

  const all_views = views.models
    .sort((a, b) => {
      if (a.id === 'cli') return -1;
      if (b.id === 'cli') return 1;
      return a.name.localeCompare(b.name);
    })
    .filter(({ name }) => !search || name.toLowerCase().indexOf(search.toLowerCase()) >= 0);

  return (
    <StyledNavLinks>
      {views.models
        .filter(
          (view, i) =>
            i < 5 && (view.type === type || (view.id === 'cli' && pathname === '/requests/cli')),
        )
        .map((view) => (
          <NavLink
            key={view.id}
            to={`${prefix ? `/${prefix}` : ''}/${type}/${formatPath(view.name, {
              escape_parentheses: false,
            })}`}>
            <Div w={100} flex={{ align: 'center', justify: 'space-between' }}>
              <Text subtitle size="m" ellipsis>
                {view.name}
              </Text>
              {view?.id === 'cli' && (
                <Tooltip
                  align="right"
                  offset={[-8, 12]}
                  tooltip={is_cli_active ? 'CLI connected' : 'CLI disconnected'}>
                  <StyledActivityIndicator active={is_cli_active} />
                </Tooltip>
              )}
            </Div>
          </NavLink>
        ))}
      {views?.models.length > 5 && (
        <Dropdown
          placement="right-start"
          block
          p={0}
          m={{ l: 2 }}
          parent_width={{ min: 248, max: 248 }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <ClickableArea highlight={opened} onClick={() => toggle(!opened)}>
                <Div flex={{ align: 'center', justify: 'space-between' }}>
                  <Text muted subtitle size="m" ellipsis>
                    See all {views?.models.length} views...
                  </Text>
                </Div>
              </ClickableArea>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <StyledCardSection muted p={{ x: 3, y: 1 }}>
                <Text size="xs" subtitle>
                  Saved Views
                </Text>
              </StyledCardSection>
              <StyledCardSection p={{ x: 3, y: 2 }}>
                <Search focus small value={search} onChange={setSearch} />
              </StyledCardSection>
              <StyledCardSection max_h={{ px: 204 }} scroll>
                {all_views.length === 0 && search && (
                  <Text muted p={{ x: 3, y: 2 }}>
                    No matching views...
                  </Text>
                )}
                {all_views.map((view, i) => (
                  <Fragment key={view.id}>
                    {i !== 0 && <Divider />}
                    <ClickableArea
                      w={100}
                      p={{ x: 3, y: 2 }}
                      onClick={() => toggle(false)}
                      to={`${prefix ? `/${prefix}` : ''}/${type}/${formatPath(view.name, {
                        escape_parentheses: false,
                      })}`}>
                      <Text subtitle size="m" ellipsis>
                        {view.name}
                      </Text>
                    </ClickableArea>
                  </Fragment>
                ))}
              </StyledCardSection>
            </>
          )}
        </Dropdown>
      )}
    </StyledNavLinks>
  );
};

const GetStartedNavLink = styled(StyledNavLinks)`
  padding: 0px;

  & > a {
    padding: 2px 8px;
    padding-right: 2px;
  }
`;
const GetStartedCloseButton = ({
  hide = true,
  onClick,
}: {
  hide?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}) => {
  return (
    <StyledGetStartedCloseButton hide={hide}>
      <Tooltip tooltip="Dismiss tab" align="right" offset={[-2, 12]}>
        <Button minimal small icon="close" p={1} h={{ px: 28 }} w={{ px: 28 }} onClick={onClick} />
      </Tooltip>
    </StyledGetStartedCloseButton>
  );
};
const StyledGetStartedCloseButton = styled.div<{ hide: boolean }>`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  width: ${({ hide }) => (hide ? '0px' : 'auto')};

  ${GetStartedNavLink}:hover & {
    visibility: visible;
    width: auto;
  }

  button {
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover {
    background-color: ${({ theme }) => theme.colors.outline.hover.neutral};
  }
  span {
    cursor: pointer;
  }
`;
const GetStartedBadge = styled(Badge)`
  visibility: visible;
  margin: 4px;
  color: ${({ theme }) => theme.colors.on.neutral.primary}!important;

  ${GetStartedNavLink}:hover & {
    visibility: hidden;
    position: absolute;
  }
`;

const DashboardNav = () => {
  const {
    subscription,
    setNavCollapsed,
    nav_collapsed,
    organization,
    get_started_pending_steps,
    show_get_started,
    setShowGetStarted,
    active_cli_sessions,
  } = useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const { query } = useSearchQuery<{
    date?: DateRange;
  }>();

  const { data: event_views } = useSWR(APIMethodKeys.views.list({ type: 'events' }), () =>
    HookdeckAPI.views.list({ type: 'events' }),
  );
  const { data: request_views } = useSWR(APIMethodKeys.views.list({ type: 'requests' }), () =>
    HookdeckAPI.views.list({ type: 'requests' }),
  );

  const { data: issues_count_response } = useSWR(
    APIMethodKeys.issues.count({ status: 'OPENED' }),
    () => HookdeckAPI.issues.count({ status: 'OPENED' }),
    {
      refreshInterval: 1000 * 60,
    },
  );

  const history = useHistory();

  const issues_count = issues_count_response?.count || 0;

  const closeGetStarted = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setShowGetStarted(false);
    if (pathname === '/get-started') {
      history.push('/');
    }
  };

  return (
    <StyledDashboardNav>
      <StyledDashboardNavSection p={{ x: 2, y: 3 }}>
        <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
          <Dropdown
            block
            placement="bottom-start"
            p={0}
            renderToggle={(opened, toggle) => (
              <ClickableArea
                on="background"
                rounded
                block
                highlight={opened}
                flex={{ align: 'center' }}
                onClick={() => toggle(!opened)}
                p={{ x: 2, y: 1.5 }}>
                <Avatar name={organization!.name} small />
                <Div m={{ l: 2 }}>
                  <Text ellipsis text_wrap={false} subtitle style={{ maxWidth: '143px' }}>
                    {organization!.name}
                  </Text>
                </Div>
              </ClickableArea>
            )}>
            <TeamSelection />
          </Dropdown>
          {!nav_collapsed && (
            <Button on="background" minimal onClick={() => setNavCollapsed(true)} p={{ x: 1.5 }}>
              <Icon muted icon="left_panel_close" />
            </Button>
          )}
        </Div>
      </StyledDashboardNavSection>
      <StyledDashboardNavSection p={{ x: 2, y: 4 }} border grow>
        <Div>
          {show_get_started !== false && (
            <GetStartedNavLink>
              <NavLink to="/get-started">
                <Icon left icon="grid_view" pointer />
                <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                  Get Started
                </Text>
                {pathname === '/get-started' ? (
                  <GetStartedCloseButton hide={false} onClick={closeGetStarted} />
                ) : (
                  <>
                    {get_started_pending_steps > 0 && (
                      <GetStartedBadge subtle>{get_started_pending_steps}</GetStartedBadge>
                    )}
                    <GetStartedCloseButton onClick={closeGetStarted} />
                  </>
                )}
              </NavLink>
            </GetStartedNavLink>
          )}
          <Text muted heading size="s" m={{ x: 2, t: show_get_started ? 4 : 0 }}>
            Configure
          </Text>
          <StyledNavLinks>
            <NavLink to="/connections">
              <Icon left icon="connections" pointer />
              <Text subtitle size="m">
                Connections
              </Text>
            </NavLink>
          </StyledNavLinks>
          <Route path={['/connections', '/sources', '/transformations', '/destinations']}>
            <StyledViewsContainer flex={{ direction: 'column' }}>
              <Div m={{ l: 6 }}>
                <ResourceLink resource="sources" />
                <ResourceLink resource="destinations" />
                <ResourceLink resource="transformations" />
              </Div>
            </StyledViewsContainer>
          </Route>
          <Text muted heading size="s" m={{ x: 2, t: 4 }}>
            Monitor
          </Text>
          <StyledNavLinks>
            <NavLink to="/issues">
              <Icon left icon="issues" pointer />
              <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                Issues
                {issues_count > 0 && (
                  <Badge danger subtle>
                    {issues_count || 1}
                  </Badge>
                )}
              </Text>
            </NavLink>
            <Route path={['/issues', '/issue-triggers']}>
              <StyledViewsContainer flex={{ direction: 'column' }}>
                <Div m={{ l: 6 }}>
                  <StyledNavLinks>
                    <NavLink to={`/issue-triggers`}>
                      <Text subtitle size="m" ellipsis>
                        Issue Triggers
                      </Text>
                    </NavLink>
                  </StyledNavLinks>
                </Div>
              </StyledViewsContainer>
            </Route>
            <NavLink
              to={`/metrics${
                pathname.includes('metrics') && !isEmpty(query?.date)
                  ? `?${stringifyQuery({ date: query.date })}`
                  : ''
              }`}
              isActive={() => pathname === '/metrics'}>
              <Icon left icon="metrics" pointer />
              <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                Metrics
              </Text>
              {!subscription?.features?.includes('metrics') && (
                <Icon muted icon="upgrade" m={{ r: 0.5 }} />
              )}
            </NavLink>
            {subscription?.features?.includes('metrics') && (
              <Route path="/metrics">
                <StyledViewsContainer flex={{ direction: 'column' }}>
                  <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                    <StyledNavLinks>
                      {Object.entries(metric_categories).map(([key, { label }]) => (
                        <NavLink
                          key={key}
                          to={`/metrics/${key}${
                            pathname.includes('metrics') && !isEmpty(query?.date)
                              ? `?${stringifyQuery({ date: query.date })}`
                              : ''
                          }`}>
                          <Text subtitle size="m" ellipsis>
                            {label}
                          </Text>
                        </NavLink>
                      ))}
                    </StyledNavLinks>
                  </Div>
                </StyledViewsContainer>
              </Route>
            )}
          </StyledNavLinks>
          <Text muted heading size="s" m={{ x: 2, t: 4 }}>
            Inspect
          </Text>
          <StyledNavLinks>
            {inspect_sections.map((link) => (
              <Fragment key={link.key}>
                <NavLink to={link.path} isActive={() => pathname === link.path}>
                  <Icon left icon={link.icon} pointer />
                  <Text subtitle size="m" ellipsis>
                    {link.label}
                  </Text>
                </NavLink>
                {request_views && link.key === 'requests' && request_views?.count > 0 && (
                  <ViewsContextProvider type="requests" redirect_root="/requests">
                    <Route path="/requests">
                      <StyledViewsContainer flex={{ direction: 'column' }}>
                        <Route path="/requests">
                          <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                            <Views type="requests" />
                          </Div>
                        </Route>
                      </StyledViewsContainer>
                    </Route>
                  </ViewsContextProvider>
                )}
                {event_views && link.key === 'events' && event_views?.count > 0 && (
                  <ViewsContextProvider type="events" redirect_root="/events">
                    <Route path="/events">
                      <StyledViewsContainer flex={{ direction: 'column' }}>
                        <Route path="/events">
                          <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                            <Views type="events" />
                          </Div>
                        </Route>
                      </StyledViewsContainer>
                    </Route>
                  </ViewsContextProvider>
                )}
              </Fragment>
            ))}
          </StyledNavLinks>
        </Div>
      </StyledDashboardNavSection>
      <NavFooter />
    </StyledDashboardNav>
  );
};

export default DashboardNav;
